import Header from "../components/Header";
import SingleStep from "../components/SingleStep";
import Link from "next/link";

export default function Error() {
  return (
    <SingleStep prefix="Sorry the page you're looking for was not found." first>
      <h1>404</h1>
      <h3>Page not found</h3>
      <Link href="/">
        <a>Back to homepage</a>
      </Link>
    </SingleStep>
  );
}
